import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup:`}</p>
    <p>{`Single Arm OHS 3×6/arm (light)`}</p>
    <p>{`OH Band Pull Aparts 3×12`}</p>
    <p>{`then,`}</p>
    <p>{`OHS 4×6\\@80% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`9-Hang Squat Snatch (95/65)`}</p>
    <p>{`18-Wall Balls (20/14)`}</p>
    <p>{`7-Hang Squat Snatch`}</p>
    <p>{`14-Wall Balls`}</p>
    <p>{`5-Hang Squat Snatch`}</p>
    <p>{`10-Wall Balls`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`There are signs up for no parking (street sweeping) from 7am-5pm
today so please park on Washington or a side street until they are
finished.  You are now able to walk on the concrete in back!  `}</em></strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`Reminder:  Bootcamp classess are Tuesday-Friday at 9:30 and evening
classes are **`}</strong>{` now offered Monday-Friday at 4:00, 5:00 & 6:00pm.`}<strong parentName="p">{`*
*`}</strong>{`   ***`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      